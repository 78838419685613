import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";

import { NationalWishlistRoute } from "../navigation/NationalRoutes";
import { useGetDealerAccountInfoByDealerId } from "../queries/useGetDealerAccountInfoByDealerId";
import { useSetErrorToast } from "../stores/hooks/useSetErrorToast";
import { LinearProgress } from "../ui/LinearProgress";

export const NationalWishlistRedirect: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, params] = useRoute(NationalWishlistRoute);
  const setErrorToast = useSetErrorToast();
  const { data: dealer } = useGetDealerAccountInfoByDealerId(params?.dealerId);

  useEffect(() => {
    if (!params) {
      const error = new Error("Wishlist params not found");
      setErrorToast(error);
      return;
    }

    if (dealer) {
      const dealerPathname = `/${dealer.dealerUrlCode}/${params.language}/dealer/${params.dealerId}/wishlist/${params.cartId}`;
      window.open(dealerPathname, "_self");
    }
  }, [dealer, params]);

  return (
    <>
      <LinearProgress className="mt-8 mb-2 h-[2px]" />
      <p className="text-ink-2 font-thin text-sm">
        {t("national.redirecting")}
      </p>
    </>
  );
};
