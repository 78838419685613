import { FetcherResult } from "../api-client/fetcher";
import { ModifiedSWRResponse, useSwrGet } from "../api-client/useSwrGet";
import { DealerAccountInfoPayload } from "../stores/slices/dealerAccountInfoSlice";

export const useGetDealerAccountInfoByDealerId = (
  dealerId?: string
): ModifiedSWRResponse<FetcherResult<Payload>, Payload> => {
  const result = useSwrGet<Payload>(
    dealerId
      ? "/retailSiteWebService/dealer/getDealerAccountInfoByDealerId.php"
      : null,
    {
      dealerId: dealerId ?? "",
    }
  );
  return result;
};

type Payload = DealerAccountInfoPayload;
